.header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
}

.header nav ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
}

.header nav ul li{
margin-left:1em;
opacity: .8;
}

.header nav ul li:hover{
    opacity: 1;
}